import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import Form from "../landing/Form";
import "../../styles/components/Cookies.scss";

function Privacidad() {
  return (
    <div>
      <Header />
      <h1 className="cookies_main_title">POLÍTICA DE COOKIES</h1>
      <main className="cookies_main">
        <section>
          <p className="cookies_text">
            El dominio www.ideasdesdecero.es utiliza procedimientos automáticos
            de recogida (Cookies) para reunir información personal como puede
            ser el tipo de navegador o sistema operativo, página de referencia,
            ruta, dominio ISP (Proveedor de Internet), etc. todo ello con el fin
            de mejorar los servicios prestados. Las Cookies nos ayudan a adaptar
            esta página web a sus necesidades personales.
          </p>
          <p className="cookies_text">
            Una "Cookie" es un pequeño archivo que se almacena en el ordenador
            del usuario y nos permite reconocerle. El conjunto de "cookies" nos
            ayuda a mejorar la calidad de nuestra web, permitiéndonos así
            personalizar hasta cierto punto la navegación de cada usuario por
            nuestra web. Las cookies actualmente son esenciales para el
            funcionamiento de internet, aportando innumerables ventajas en la
            prestación de servicios interactivos, facilitándole la navegación y
            usabilidad de nuestra web.
          </p>
          <h2 className="cookies_secondary_title">Tipología de cookies:</h2>
          <p className="cookies_text">
            Para una mayor información del usuario sobre la tipología y uso de
            las cookies le informamos que:
          </p>
          <ul className="cookies_ul_list">
            <li>
              Desde el punto de vista de{" "}
              <span className="highlight">‘tiempo de vida’ de la cookie </span>
              (tiempo que la cookie permanece activa en nuestro equipo) podemos
              diferenciar las cookies entre:
            </li>
            <ol className="cookies_ol_list">
              <li>
                <span className="highlight">Cookies de sesión: </span>
                son cookies temporales que permanecen en el archivo de cookies
                de su navegador hasta que abandone la página web, por lo que
                ninguna queda registrada en el disco duro del usuario. La
                información obtenida por medio de estas cookies, sirven para
                analizar pautas de tráfico en la web. A la larga, esto nos
                permite proporcionar una mejor experiencia para mejorar el
                contenido y facilitando su uso.
              </li>
              <li>
                <span className="highlight">Cookies persistentes: </span> son
                almacenadas en el disco duro y nuestra web las lee cada vez que
                usted realiza una nueva visita. Una cookie permanente posee una
                fecha de expiración determinada. La cookie dejará de funcionar
                después de esa fecha. Las utilizamos, generalmente, para
                facilitar la experiencia del usuario recordando sus hábitos de
                navegación o, en su caso, facilitar los servicios de compra y/o
                registro.
              </li>
            </ol>
            <li>
              Desde el punto de vista de la
              <span className="highlight"> finalidad de cada cookie</span>,
              podemos diferenciar entre:
            </li>
            <ol className="cookies_ol_list">
              <li>
                <span className="highlight">Cookies Técnicas: </span>
                son aquellas que permiten al usuario la navegación a través de
                una página web, plataforma o aplicación y la utilización de las
                diferentes opciones o servicios que en ella existan, incluyendo
                aquellas que el editor utiliza para permitir la gestión y
                operativa de la página web y habilitar sus funciones y
                servicios.
              </li>
              <li>
                <span className="highlight">
                  Las cookies técnicas, siempre que correspondan a alguno de los
                  siguientes perfiles, estarán exceptuadas del cumplimiento de
                  las obligaciones establecidas en el artículo 22.2 de la LSSI*
                </span>
                (es decir, obtención de consentimiento para su instalación y uso
                mediante la existencia de banner de aviso, política de cookies):
              </li>
              <ul className="cookies_ul_list_2">
                <li className="li_dif_style">
                  Cookies de “entrada del usuario”
                </li>
                <li className="li_dif_style">
                  Cookies de autenticación o identificación de usuario
                  (únicamente de sesión)
                </li>
                <li className="li_dif_style">
                  Cookies de seguridad del usuario
                </li>
                <li className="li_dif_style">
                  Cookies de sesión de reproductor multimedia
                </li>
                <li className="li_dif_style">
                  Cookies de sesión para equilibrar la carga
                </li>
                <li className="li_dif_style">
                  Cookies de personalización de la interfaz de usuario
                </li>
                <li className="li_dif_style">
                  Cookies de complemento (plug-in) para intercambiar contenidos
                  sociales
                </li>
              </ul>
              <li className="no_style">
                * según el Grupo de Trabajo de Expertos de la UE, en el Artículo
                29 de su Dictamen 4/2012
              </li>
            </ol>
            <ol className="cookies_ol_list">
              <li className="no_style"> </li>
              <li className="no_style"> </li>
              <li>
                <span className="highlight">
                  Cookies de preferencias o personalización:
                </span>{" "}
                son aquellas que permiten recordar información para que el
                usuario acceda al servicio con determinadas características que
                pueden diferenciar su experiencia de la de otros usuarios, como,
                por ejemplo, el idioma, el número de resultados a mostrar cuando
                el usuario realiza una búsqueda, el aspecto o contenido del
                servicio en función del tipo de navegador a través del cual el
                usuario accede al servicio o de la región desde la que accede al
                servicio, etc. Si es el propio usuario quien elige esas
                características (por ejemplo, si selecciona el idioma de un
                sitio web clicando en el icono de la bandera del país
                correspondiente), las cookies estarán exceptuadas de las
                obligaciones del artículo 22.2 de la LSSI por considerarse un
                servicio expresamente solicitado por el usuario, y ello siempre
                y cuando las cookies obedezcan exclusivamente a la finalidad
                seleccionada.
              </li>
              <li>
                <span className="highlight">
                  Cookies de análisis o medición:
                </span>{" "}
                son aquellas que permiten al responsable de estas el seguimiento
                y análisis del comportamiento de los usuarios de los sitios web
                a los que están vinculadas, incluida la cuantificación de los
                impactos de los anuncios. La información recogida mediante este
                tipo de cookies se utiliza en la medición de la actividad de los
                sitios web, aplicación o plataforma, con el fin de introducir
                mejoras en función del análisis de los datos de uso que hacen
                los usuarios del servicio.
              </li>
              <li>
                <span className="highlight">
                  Cookies de publicidad comportamental:
                </span>{" "}
                son aquellas que almacenan información del comportamiento de los
                usuarios obtenida a través de la observación continuada de sus
                hábitos de navegación, lo que permite desarrollar un perfil
                específico para mostrar publicidad en función de este.
              </li>
            </ol>
            <li>
              Como último, desde el punto de vista{" "}
              <span className="highlight">
                de la entidad que gestiona las cookies
              </span>
              , podemos diferenciar entre:
            </li>
            <ol className="cookies_ol_list">
              <li>
                <span className="highlight">Cookies propias: </span>
                son aquellas que se envían al equipo terminal del usuario desde
                un equipo o dominio gestionado por el propio editor y desde el
                que se presta el servicio solicitado por el usuario.
              </li>
              <li>
                <span className="highlight">Cookies de tercero: </span> son
                aquellas que se envían al equipo terminal del usuario desde un
                equipo o dominio que no es gestionado por el editor, sino por
                otra entidad que trata los datos obtenidos través de las
                cookies.
              </li>
            </ol>
          </ul>
          <p className="cookies_text">
            IDEAS DESDE CERO S.L. , guarda toda la información recogida a través
            de las Cookies en un formato no personalizado (dirección de IP).
            Este tipo de información obtenida a través de las Cookies no será
            revelado fuera de IDEAS DESDE CERO S.L. , salvo en aquellos casos
            indicados en el apartado ‘Relación y descripción de cookies’, ni
            utilizada para comunicaciones no solicitadas.
          </p>
          <h2 className="cookies_secondary_title">
            Privacidad adecuada– Gestión de cookies:
          </h2>
          <p className="cookies_text">
            En caso de que el usuario lo quisiera, el registro de las cookies
            podrá estar sujeto a su aceptación durante la instalación o puesta
            al día del navegador usado. El usuario puede en todo momento revocar
            su aceptación mediante las opciones de configuración de contenidos y
            privacidad disponibles en cada navegador. No obstante, en caso de
            que el usuario no permita la instalación de cookies en su navegador
            es posible que no pueda acceder a alguna de las secciones de nuestro
            sitio web.
          </p>
          <p className="cookies_text">
            Para más información sobre la configuración apropiada de las cookies
            y las opciones de activación, restricción y/o inhabilitación se debe
            acudir en la sección de ayuda de su navegador para conocer más:
          </p>
          <ul className="cookies_ul_list">
            <li className="li_dif_style">
              <a
                className="cookies_link"
                target="_blank"
                href="https://support.google.com/chrome/answer/95647?hl=es"
              >
                Más información sobre como bloquear el uso de las cookies en
                Google Chrome
              </a>
            </li>
            <li className="li_dif_style">
              <a
                className="cookies_link"
                target="_blank"
                href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?esab=a&s=cookies&r=7&as=s&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we&redirectlocale=es"
              >
                Más información sobre como bloquear el uso de las cookies en
                Firefox
              </a>
            </li>
            <li className="li_dif_style">
              <a
                className="cookies_link"
                target="_blank"
                href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
              >
                Más información sobre como bloquear el uso de las cookies en
                Internet Explorer
              </a>
            </li>
            <li className="li_dif_style">
              <a
                className="cookies_link"
                target="_blank"
                href="https://support.microsoft.com/es-es/microsoft-edge/eliminar-las-cookies-en-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09"
              >
                Más información sobre como bloquear el uso de las cookies en
                Microsoft Edge
              </a>
            </li>
            <li className="li_dif_style">
              <a
                className="cookies_link"
                target="_blank"
                href="https://support.apple.com/es-es/guide/safari/sfri11471/mac"
              >
                Más información sobre como bloquear el uso de las cookies en
                Safari: en el Mac
              </a>
            </li>
            <li className="li_dif_style">
              <a
                className="cookies_link"
                target="_blank"
                href="https://support.apple.com/es-es/HT201265"
              >
                Más información sobre como bloquear el uso de las cookies en
                Safari: en el iPhone, iPad o iPod touch
              </a>
            </li>
          </ul>
          <p className="cookies_text">
            Muchos navegadores permiten activar un modo privado mediante el cual
            las cookies se borran siempre después de su visita. Dependiendo de
            cada navegador este modo privado, puede tener diferentes nombres. A
            continuación, encontrará una lista de los navegadores más comunes y
            los diferentes nombres de este “modo privado”:
          </p>
          <ul className="cookies_ul_list">
            <li className="li_dif_style">
              Internet Explorer 8 y superior; InPrivate
            </li>
            <li className="li_dif_style">
              Safari 2 y superior; Navegación Privada
            </li>
            <li className="li_dif_style">
              Opera 10.5 y superior; Navegación Privada
            </li>
            <li className="li_dif_style">
              Fire Fox 3.5 y superior; Navegación Privada
            </li>
            <li className="li_dif_style">
              Google Chrome 10 y superior; Incógnito
            </li>
          </ul>
          <h2 className="cookies_secondary_title">
            Relación y descripción de cookies:
          </h2>
          <p className="cookies_text">
            La tabla que publicamos a continuación recoge de forma esquematizada
            las cookies anteriormente descritas y utilizadas en el sitio web
            www.ideasdesdecero.es:
          </p>
          <table className="cookies_table">
            <thead>
              <tr className="cookies_table_main--row">
                <th className="cookies_table_cell">Nombre de cookie</th>
                <th className="cookies_table_cell">Dominio</th>
                <th className="cookies_table_cell">Duración</th>
                <th className="cookies_table_cell">Finalidad</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="cookies_table_cell">cb-enabled</td>
                <td className="cookies_table_cell">Propia</td>
                <td className="cookies_table_cell">Persistente</td>
                <td className="cookies_table_cell">
                  Notifica que hemos aceptado la política de cookies
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">_gid </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">24 horas </td>
                <td className="cookies_table_cell">
                  Se usa para distinguir a los usuarios
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">_ga </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">2 años </td>
                <td className="cookies_table_cell">
                  Se usa para distinguir a los usuarios
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">1P_JAR </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">1 semana </td>
                <td className="cookies_table_cell">
                  Almacenamiento de las preferencias de usuario de google.
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">CONSENT </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">Persistente </td>
                <td className="cookies_table_cell">
                  Mejora la publicidad, suele utilizarse para orientarse al
                  usuario
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">NID </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">6 meses </td>
                <td className="cookies_table_cell">Registra una ID</td>
              </tr>
              <tr>
                <td className="cookies_table_cell">OTZ </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell"></td>
                <td className="cookies_table_cell">
                  Cookie que identifica las preferencias del usuario
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">SIDCC</td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">3 meses </td>
                <td className="cookies_table_cell">
                  Provee servicios y extrae información anónima sobre la
                  navegación
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">SAPISID </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">Persistente </td>
                <td className="cookies_table_cell">
                  Mide el rendimiento de los anuncios y proporciona
                  recomendaciones
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">SSID</td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">Persistente </td>
                <td className="cookies_table_cell">
                  Mide el rendimiento de los anuncios y proporciona
                  recomendaciones
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">HSID </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">Persistente </td>
                <td className="cookies_table_cell">
                  Mide el rendimiento de los anuncios y proporciona
                  recomendaciones
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">APISID</td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">Persistente </td>
                <td className="cookies_table_cell">
                  Mide el rendimiento de los anuncios y proporciona
                  recomendaciones
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">SID</td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">Sesión </td>
                <td className="cookies_table_cell">
                  Recoge información para el buscador de google
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">__Secure-3PAPISID </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">2 años </td>
                <td className="cookies_table_cell">
                  Estas cookies se utilizan para entregar anuncios más
                  relevantes para usted y sus intereses
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">__Secure-3PSID </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell">2 años </td>
                <td className="cookies_table_cell">
                  Estas cookies se utilizan para entregar anuncios más
                  relevantes para usted y sus intereses
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">OGP </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell"> </td>
                <td className="cookies_table_cell">
                  Esta cookie permite la funcionalidad de Google Maps
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell">OGPC </td>
                <td className="cookies_table_cell">Google</td>
                <td className="cookies_table_cell"> </td>
                <td className="cookies_table_cell">
                  Esta cookie permite la funcionalidad de Google Maps
                </td>
              </tr>
              <tr>
                <td className="cookies_table_cell no_border">__stripe_mid </td>
                <td className="cookies_table_cell no_border">Stripe</td>
                <td className="cookies_table_cell no_border">1 año </td>
                <td className="cookies_table_cell no_border">
                  Funcional. Previene el fraude
                </td>
              </tr>
            </tbody>
          </table>
          <p className="cookies_text">
            Para conocer más sobre las cookies de Google Analytics puede visitar
            la siguiente página:{" "}
            <a
              className="cookies_link"
              target="_blank"
              href="https://policies.google.com/technologies/cookies?hl=es"
            >
              https://policies.google.com/technologies/ cookies?hl=es
            </a>{" "}
            Puede desactivar las cookies de Google Analytics a través del
            siguiente enlace: https://tools.google.com/dlpage/gaoptout .
            (eliminar si no se usa Google Analytics)
          </p>
          <p className="cookies_text">
            Para más información sobre cookies y sus derechos como usuario puede
            consultar la Guía sobre el uso de cookies elaborada por la Agencia
            Española de Protección de Datos con la colaboración de las
            asociaciones ADIGITAL, Anunciantes, AUTOCONTROL e IAB Spain:{" "}
            <a
              className="cookies_link"
              target="_blank"
              href="https://www.aepd.es/media/guias/guia-cookies.pdf"
            >
              https://www.aepd.es/media/guias/guia-cookies.pdf
            </a>
          </p>
        </section>
      </main>
      <Form />
      <Footer />
    </div>
  );
}

export default Privacidad;
