import "../../styles/components/Hero.scss";
/* import video from "../../images/video.mp4"; */
import logoPlataformaCero from "../../images/logo_plataforma.png";
import videoHero from "../../images/videoplataforma0.mp4";

function Hero() {
  if (window.innerWidth < 700) {
    return (
      <section className="hero">
        <video className="hero_video" width="100%" loop autoPlay muted>
          <source src={videoHero} type="video/mp4" />
        </video>
        {/* <div className="hero_banner_box_img"></div> */}
        <article className="hero_banner">
          <div className="hero_img_plataforma_box">
            <img
              className="hero_img_plataforma"
              src={logoPlataformaCero}
              alt="Logo Plataforma Cero"
            />
          </div>
        </article>
      </section>
    );
  } else if (window.innerWidth >= 700) {
    return (
      <section className="hero">
        <video className="hero_video" width="100%" loop autoPlay muted>
          <source src={videoHero} type="video/mp4" />
        </video>
        <article className="hero_banner">
          <div className="hero_img_plataforma_box">
            <img
              className="hero_img_plataforma"
              src={logoPlataformaCero}
              alt="Logo Plataforma Cero"
            />
          </div>
        </article>
        {/*   <p className="hero_text_1">A esto,</p>
        <p className="hero_text_2">se le llama sampling</p> */}
      </section>
    );
  }
}

export default Hero;
