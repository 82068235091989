//Cambiar urls a oficiales

// Landing Form
const landingForm = (data) => {
  return fetch("http://localhost:4000/send_email_landing", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

// Embajadores Form
const embajadoresForm = (data) => {
  return fetch("http://localhost:4000/send_email_embajadores", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

// Embajadores Form
const marcasForm = (data) => {
  return fetch("http://localhost:4000/send_email_marcas", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
};

const objToExport = {
  landingForm: landingForm,
  embajadoresForm: embajadoresForm,
  marcasForm: marcasForm,
};

export default objToExport;
