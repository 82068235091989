import "../../styles/components/Form.scss";
import "../../styles/components/Error_messages.scss";
import { useState } from "react";

function Form(props) {
  const [formInfo, setFormInfo] = useState({
    nombre: "",
    email: "",
    empresa: "",
    mensaje: "",
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorBorderName, setErrorBorderName] = useState(false);
  const [errorBorderEmail, setErrorBorderEmail] = useState(false);
  const [submit, setSubmit] = useState(false);

  function getFormInfo(ev) {
    const input = ev.target.name;
    const value = ev.target.value;

    if (input === "nombre") {
      setFormInfo({ ...formInfo, nombre: value });
    } else if (input === "email") {
      setFormInfo({ ...formInfo, email: value });
    } else if (input === "empresa") {
      setFormInfo({ ...formInfo, empresa: value });
    } else if (input === "mensaje") {
      setFormInfo({ ...formInfo, mensaje: value });
    }
  }

  function sendFormInfo(ev) {
    ev.preventDefault();

    if (
      formInfo.nombre === "" ||
      formInfo.email === "" ||
      (formInfo.nombre === "" && formInfo.email === "")
    ) {
      setErrorMessage(true);

      if (formInfo.nombre === "" && formInfo.email !== "") {
        setErrorBorderName(true);
        setErrorBorderEmail(false);
      } else if (formInfo.nombre !== "" && formInfo.email === "") {
        setErrorBorderName(false);
        setErrorBorderEmail(true);
      } else if (formInfo.nombre === "" && formInfo.email === "") {
        setErrorBorderName(true);
        setErrorBorderEmail(true);
      }
    } else {
      props.sendLandingFormInfo(formInfo);
      setSubmit(true);
      setErrorMessage(false);
      setErrorBorderName(false);
      setErrorBorderEmail(false);
      resetForm();
    }
  }

  function resetForm() {
    setFormInfo({
      nombre: "",
      email: "",
      empresa: "",
      mensaje: "",
    });
  }

  if (submit === false) {
    return (
      <section className="form" id="contacto">
        <h2 className="form_title">
          ¡Déjanos un <span className="highlight_green">mensaje!</span>
        </h2>
        <article className="form_fields">
          <form className="form_inputs" onSubmit={sendFormInfo}>
            <label htmlFor="nombre" hidden>
              Nombre
            </label>
            <input
              className={`form_input ${
                errorBorderName ? "highlight_error" : ""
              }`}
              type="text"
              id="nombre"
              name="nombre"
              placeholder="Tu nombre *"
              onChange={getFormInfo}
              value={formInfo.nombre}
            ></input>
            <label htmlFor="email" hidden>
              Email
            </label>
            <input
              className={`form_input ${
                errorBorderEmail ? "highlight_error" : ""
              }`}
              type="email"
              id="email"
              name="email"
              placeholder="Tu email *"
              onChange={getFormInfo}
              value={formInfo.email}
            ></input>
            <label htmlFor="empresa" hidden>
              Empresa
            </label>
            <input
              className="form_input"
              type="text"
              id="empresa"
              name="empresa"
              placeholder="Tu empresa (Si quieres)"
              onChange={getFormInfo}
              value={formInfo.empresa}
            ></input>
            <label htmlFor="mensaje" hidden>
              Mensaje
            </label>
            <textarea
              className="form_message"
              id="mensaje"
              name="mensaje"
              placeholder="Tu mensaje"
              onChange={getFormInfo}
              value={formInfo.mensaje}
            ></textarea>
            <button className="form_submit" type="submit">
              Enviar
            </button>
          </form>
          <div className="form_contact">
            <h3 className="form_contact_title_2">
              Si lo de los formularios no te termina de gustar, también puedes
              contactarnos en:
            </h3>
            <article className="form_contact_box">
              <p className="form_contact_text">
                <i className="fa-regular fa-envelope"></i>
                <a
                  target="_blank"
                  className="form_contact_link"
                  href="mailto:sampling@ideasdesdecero.es"
                >
                  sampling@ideasdesdecero.es
                </a>
              </p>
              <p className="form_contact_text">
                <i className="fa-solid fa-mobile-screen"></i>
                <a
                  target="_blank"
                  className="form_contact_link"
                  href="tel:+34917218786"
                >
                  917 218 786
                </a>
              </p>
              <p className="form_contact_text">
                <i className="fa-solid fa-location-dot"></i>
                <a
                  target="_blank"
                  className="form_contact_link"
                  href="https://www.google.com/maps/search/manuel+uribe+13+-15/@40.4648098,-3.6660924,17z/data=!3m1!4b1?entry=ttu"
                >
                  C/ Manuel Uribe 13 - 15. 28033 Madrid
                </a>
              </p>
            </article>
          </div>
        </article>
        <p className={`error_message ${errorMessage ? "show_error" : ""}`}>
          Rellena todos los campos obligatorios antes de enviar el formulario
        </p>
        <small className="form_info_captcha">
          Este formulario funciona con reCAPTCHA de Google, puedes consultar la
          Política de privacidad y los terminos de uso del servicio. Tratamos la
          información que nos facilita con el fin comercial establecido. Los
          datos proporcionados se conservarán hasta el fin de la prestación del
          servicio contratado o hasta que usted ejerza su derecho de cancelación
          por tanto tiene derecho a ejercer sus derechos de acceso,
          rectificación, limitación del tratamiento, supresión, oposición,
          bloqueo y/o portabilidad de los datos enviando una carta a la
          siguiente dirección: IDEAS DESDE CERO S.L. - Calle Manuel Uribe,
          13-15. 28033 Madrid o al correo electrónico
          administracion@ideasdesdecero.es. Los datos no se cederán a terceros.
        </small>
      </section>
    );
  } else {
    return (
      <section className="form_2" id="contacto_2">
        <h2 className="form_title">
          ¡Gracias por tu <span className="highlight_green">mensaje!</span>
        </h2>
        <h2 className="form_title_2">
          Nos pondremos en contacto contigo{" "}
          <span className="highlight_green">enseguida.</span>
        </h2>
        <div className="form_contact_2">
          <h3 className="form_contact_title_3">
            Recuerda que también puedes contactarnos en:
          </h3>
          <article className="form_contact_box_2">
            <p className="form_contact_text">
              <i className="fa-regular fa-envelope"></i>
              <a
                target="_blank"
                className="form_contact_link"
                href="mailto:sampling@ideasdesdecero.es"
              >
                sampling@ideasdesdecero.es
              </a>
            </p>
            <p className="form_contact_text">
              <i className="fa-solid fa-mobile-screen"></i>
              <a
                target="_blank"
                className="form_contact_link"
                href="tel:+34917218786"
              >
                917 218 786
              </a>
            </p>
            <p className="form_contact_text">
              <i className="fa-solid fa-location-dot"></i>
              <a
                target="_blank"
                className="form_contact_link"
                href="https://www.google.com/maps/search/manuel+uribe+13+-15/@40.4648098,-3.6660924,17z/data=!3m1!4b1?entry=ttu"
              >
                C/ Manuel Uribe 13 - 15. 28033 Madrid
              </a>
            </p>
          </article>
        </div>
      </section>
    );
  }
}

export default Form;
