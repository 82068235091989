import "../styles/App.scss";
import Landing from "./landing/Landing";
import Marcas from "./forms/Marcas";
import Embajadores from "./forms/Embajadores";
import AvisoLegal from "./legal/Aviso_legal";
import Privacidad from "./legal/Privacidad";
import Cookies from "./legal/Cookies";
import React from "react";
import { Routes, Route } from "react-router-dom";
import ScrollToTop from "./reusables/ScrollToTop";
import api from "../services/api-forms";

function App() {
  // Functions
  const sendLandingFormInfo = (formInfo) => {
    api.landingForm(formInfo).then((response) => {
      if (response.success === true) {
        console.log("Email enviado correctamente");
      } else {
        console.log(
          "¡Ups! Parece que ha habido un problema. Inténtalo de nuevo"
        );
      }
    });
  };

  const sendEmbajadoresFormInfo = (formInfo) => {
    api.embajadoresForm(formInfo).then((response) => {
      if (response.success === true) {
        console.log("Email enviado correctamente");
      } else {
        console.log(
          "¡Ups! Parece que ha habido un problema. Inténtalo de nuevo"
        );
      }
    });
  };

  const sendMarcasFormInfo = (formInfo) => {
    api.marcasForm(formInfo).then((response) => {
      if (response.success === true) {
        console.log("Email enviado correctamente");
      } else {
        console.log(
          "¡Ups! Parece que ha habido un problema. Inténtalo de nuevo"
        );
      }
    });
  };

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={<Landing sendLandingFormInfo={sendLandingFormInfo} />}
        ></Route>
        <Route
          path="/marcas"
          element={<Marcas sendMarcasFormInfo={sendMarcasFormInfo} />}
        ></Route>
        <Route
          path="/embajadores"
          element={
            <Embajadores sendEmbajadoresFormInfo={sendEmbajadoresFormInfo} />
          }
        ></Route>
        <Route path="/aviso-legal" element={<AvisoLegal />}></Route>
        <Route path="/privacidad" element={<Privacidad />}></Route>
        <Route path="/cookies" element={<Cookies />}></Route>
      </Routes>
    </>
  );
}

export default App;
