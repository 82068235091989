import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import Form from "../landing/Form";
import { NavLink } from "react-router-dom";
import "../../styles/components/Aviso_legal.scss";

function Aviso_legal() {
  return (
    <div>
      <Header />
      <h1 className="aviso_main_title">
        AVISO LEGAL Y CONDICIONES DE USO DEL SITIO
      </h1>
      <main className="aviso_main">
        <section>
          <p className="aviso_text">
            En virtud del cumplimiento de la Ley 34/2002, de 11 de Julio, de
            Servicios de la Sociedad de la Información y de Comercio
            Electrónico, le informamos:
          </p>
          <h2 className="aviso_secondary_title">1.- Objeto.</h2>
          <p className="aviso_text">
            El prestador de servicios de la página web www.ideasdesdecero.es es
            IDEAS DESDE CERO S.L. , con domicilio social en C/MANUEL URIBE,
            13-15 LOCAL (MADRID), CIF nº B86272564 La empresa consta inscrita en
            el Registro Mercantil de Madrid en el Tomo 23122, folio 33, sección
            8ª, hoja M-524379, inscripción 1ª
          </p>
          <p className="aviso_text">
            A efectos de este documento el teléfono de contacto es 917 218 786 y
            el correo electrónico de contacto es{" "}
            <a
              target="_blank"
              className="aviso_link"
              href="mailto:sampling@ideasdesdecero.es"
            >
              administracion@ideasdesdecero.es
            </a>
          </p>
          <p className="aviso_text">
            Este Aviso Legal regula la utilización de dicho dominio.
          </p>
          <p className="aviso_text">
            La utilización de este sitio web implica la aceptación por parte del
            Usuario de las condiciones de uso incluidas en este Aviso como
            también nuestras políticas sobre ‘Protección de Datos’ y ‘Política
            de uso de Cookies’. En el caso de que ciertos servicios contenidos
            y/o herramientas ofrecidos a través de este “Portal” requirieran de
            la aplicación condiciones particulares estas se pondrán a
            disposición del Usuario.
          </p>
          <p className="aviso_text">
            Por otra parte, IDEAS DESDE CERO S.L. , advierte que, tanto los
            contenidos y servicios de esta página web como las propias
            condiciones de utilización, pueden ser modificados sin notificación
            previa.
          </p>
          <h2 className="aviso_secondary_title">
            2. - Condiciones Generales de Uso.
          </h2>
          <p className="aviso_text">
            El Usuario se compromete a que, en los apartados en que sea
            necesario que se registre para poder acceder a los mismos, facilitar
            datos veraces, exactos y completos sobre su identidad. Además, se
            compromete a mantener actualizados los datos personales que pudieran
            ser proporcionados a titular del dominio, por lo tanto, único
            responsable de las falsedades o inexactitudes que realice.
          </p>
          <p className="aviso_text">
            Se informa que en caso de ser menor de edad deberá obtener el
            permiso de sus padres, tutores o representantes legales para poder
            acceder a los servicios prestados. IDEAS DESDE CERO S.L. , no se
            responsabiliza en el caso de que los datos sobre este tema sean
            inexactos o falsos.
          </p>
          <p className="aviso_text">
            El “portal” sólo puede ser utilizado con propósitos legales por
            tanto el usuario se obliga a hacer un uso lícito y honrado del
            portal y conforme a las presentes Condiciones Generales de Uso, a no
            utilizar los servicios del “portal” para la realización de
            actividades contrarias a las legislación española, a la moral y al
            orden público, asumiendo por parte del usuario todas las
            responsabilidades de daños y perjuicios frente al titular del
            dominio o terceros que pudieran derivarse de prácticas ilegales o no
            permitidas entres otras y a título enunciativo y no limitativo:
          </p>
          <p className="aviso_text">
            Realizar sin previo consentimiento por parte del titular del dominio
            cualquier manipulación o alteración de esta página, no asumiendo el
            titular del dominio ninguna responsabilidad que pudiera derivarse,
            de dicha manipulación o alteración por terceros
          </p>
          <p className="aviso_text">
            Realizar cualquier acto que pueda dañar, inutilizar, sobrecargar, o
            deteriorar el Portal y los servicios y/o impedir el normal uso y
            utilización por parte de los Usuarios
          </p>
          <p className="aviso_text">
            Introducir y/o utilizar programas de ordenador, datos, archivos
            defectuosos, virus, código malicioso, equipos informáticos o de
            telecomunicaciones o cualquier otro, independientemente de su
            naturaleza que pueda causar daños en el Portal, en cualquiera de los
            servicios, o en cualesquiera activos (físicos o lógicos) de los
            sistemas de información de titular del dominio
          </p>
          <p className="aviso_text">
            Violar los derechos de terceros a la intimidad, la propia imagen, la
            protección de datos al secreto en las comunicaciones, a la propiedad
            intelectual e industrial.
          </p>
          <p className="aviso_text">
            Ocultar y falsear el origen de mensajes de correo electrónico
          </p>
          <p className="aviso_text">
            Utilizar identidades falsas, suplantar la identidad de otros en la
            utilización del Portal o en la utilización de cualquiera de los
            servicios
          </p>
          <p className="aviso_text">
            Reproducir, distribuir, modificar o copiar el contenido de esta
            página, salvo que de disponga de la autorización del titular del
            dominio o esté legalmente autorizado.
          </p>
          <p className="aviso_text">
            Transmitir a terceros no autorizados los nombres de Usuario y las
            claves de acceso
          </p>
          <p className="aviso_text">
            IDEAS DESDE CERO S.L. , no responde de los enlaces (LINKS) a otras
            páginas de Internet de terceros y su existencia no implica que IDEAS
            DESDE CERO S.L. , apruebe o acepte sus contenidos y servicios. Estas
            otras páginas web no están controladas por IDEAS DESDE CERO S.L. ,
            ni cubiertas por la presente Política de Privacidad. Si accede a
            otras páginas web utilizando los enlaces proporcionados, los
            operadores de dichos sitios web podrán recoger su información
            personal. Asegúrese que está conforme con las Políticas de
            Privacidad de estas terceras páginas web antes de facilitar ningún
            tipo de información personal.
          </p>
          <p className="aviso_text">
            Con carácter general, el titular del dominio, excluye su
            responsabilidad por los daños y perjuicios de cualquier naturaleza e
            índole que pudieran derivarse del uso del sitio web de, así como a
            los daños y perjuicios derivados de la infracción de los derechos de
            propiedad Intelectual e Industrial por parte de los usuarios y/o la
            falta de veracidad, exactitud, y actualidad de los contenidos, ni le
            podrán ser exigidas responsabilidades por la interrupción del
            servicio, inadecuado funcionamiento o imposibilidad de acceso al
            servicio.
          </p>
          <p className="aviso_text">
            El titular del dominio no será responsable por los daños y
            perjuicios causados por la presencia de virus o cualquier otro
            software lesivo que pueda producir alteraciones en el sistema
            informático del Usuario.
          </p>
          <p className="aviso_text">
            El sitio web, incluyendo a título enunciativo, pero no limitativo,
            su programación, diseños, logotipos, texto y/o gráficos son
            propiedad del prestador o en su caso dispone de licencia o
            autorización expresa por parte de los autores.
          </p>
          <p className="aviso_text">
            Independientemente de la finalidad para la que fueran destinados, la
            reproducción total o parcial, uso, explotación, distribución y
            comercialización, requiere en todo caso de la autorización escrita
            previa por parte del titular del dominio.
          </p>
          <p className="aviso_text">
            El usuario se compromete a no realizar ningún acto en contra de los
            derechos de propiedad intelectual o industrial del autor.
          </p>
          <p className="aviso_text">
            El prestador de servicios de la web autoriza expresamente a que
            terceros puedan redirigir directamente a los contenidos concretos
            del sitio web, debiendo en todo caso redirigir al sitio web
            principal del prestador.
          </p>
          <h2 className="aviso_secondary_title">3.- Uso de cookies</h2>
          <p className="aviso_text">
            IDEAS DESDE CERO S.L. , en calidad de prestador de servicios de esta
            web declara que utiliza procedimientos automáticos de recogida de
            información para guardar el registro de los Usuarios que visitan su
            página web. Pulsa aquí para acceder en nuestra{" "}
            <NavLink className="aviso_link" to={"/cookies"}>
              política de uso de cookies
            </NavLink>
          </p>
          <h2 className="aviso_secondary_title">4.- Protección de datos</h2>
          <p className="aviso_text">
            Puede consultar nuestra política sobre protección de datos de
            carácter personal en el{" "}
            <NavLink className="aviso_link" to={"/privacidad"}>
              siguiente apartado
            </NavLink>
          </p>
          <h2 className="aviso_secondary_title">5.- Legislación aplicable</h2>
          <p className="aviso_text">
            Las presentes Condiciones Generales de Uso se regirán en todo
            momento por lo dispuesto en la legislación española.
          </p>
        </section>
      </main>
      <Form />
      <Footer />
    </div>
  );
}

export default Aviso_legal;
