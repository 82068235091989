import { NavLink } from "react-router-dom";
import { useState } from "react";
import logos from "../../images/logo ideo 2.png";
import "../../styles/components/Footer.scss";

function Footer() {
  const [submit, setSubmit] = useState(false);

  return (
    <footer className="footer">
      <div className="footer_heart">
        <div className="footer__logos">
          <img
            className="footer__logos_img"
            src={logos}
            alt="Logos Ideo y Mr Gates"
          />
        </div>
        <div className="footer__info">
          <nav className="footer__menu">
            <ul className="footer__list">
              <li className="footer__list_item">
                <NavLink
                  className="footer__list_item_link"
                  to={"/aviso-legal"}
                  target="_blank"
                >
                  Aviso Legal
                </NavLink>
              </li>
              <li className="footer__list_item">
                <NavLink
                  className="footer__list_item_link"
                  to={"/privacidad"}
                  target="_blank"
                >
                  Privacidad
                </NavLink>
              </li>
              <li className="footer__list_item">
                <NavLink
                  className="footer__list_item_link"
                  to={"/cookies"}
                  target="_blank"
                >
                  Cookies
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="footer__copy_box">
            <small className="footer__copy">© 2023 Ideas Desde Cero S.L.</small>
            <small className="footer__copy">
              Todos los derechos reservados
            </small>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
