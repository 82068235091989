import { HashLink as Link } from "react-router-hash-link";
import "../../styles/components/Header.scss";

function Header() {
  return (
    <header className="header">
      <nav className="header__menu">
        <ul className="header__list">
          <li className="header__list--item nosotros">
            <Link to="/#nosotros" className="header__list--link">
              Nosotros
            </Link>
          </li>
          <li className="header__list--item servicios">
            <Link to="/#servicios" className="header__list--link">
              Servicios
            </Link>
          </li>
          <li className="header__list--item contacto">
            <Link to="/#contacto" className="header__list--link">
              Contacto
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
