import "../../styles/components/Marcas.scss";
import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";

function Marcas(props) {
  const [formInfo, setFormInfo] = useState({
    nombre: "",
    telefono: "",
    email: "",
    marca: "",
    target: "",
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [submit, setSubmit] = useState(false);

  function getFormInfo(ev) {
    const input = ev.target.name;
    const value = ev.target.value;

    if (input === "nombre") {
      setFormInfo({ ...formInfo, nombre: value });
    } else if (input === "telefono") {
      setFormInfo({ ...formInfo, telefono: value });
    } else if (input === "email") {
      setFormInfo({ ...formInfo, email: value });
    } else if (input === "marca") {
      setFormInfo({ ...formInfo, marca: value });
    } else if (input === "target") {
      setFormInfo({ ...formInfo, target: value });
    }
  }

  function sendFormInfo(ev) {
    ev.preventDefault();

    if (
      formInfo.nombre === "" ||
      formInfo.email === "" ||
      formInfo.telefono === "" ||
      formInfo.marca === "" ||
      formInfo.target === "" ||
      (formInfo.nombre === "" &&
        formInfo.email === "" &&
        formInfo.telefono === "" &&
        formInfo.marca === "" &&
        formInfo.target === "")
    ) {
      setErrorMessage(true);
    } else {
      props.sendMarcasFormInfo(formInfo);
      setSubmit(true);
      resetForm();
      setErrorMessage(false);
    }
  }

  function resetForm() {
    setFormInfo({
      nombre: "",
      telefono: "",
      email: "",
      marca: "",
      target: "",
    });
  }

  function resetSubmit() {
    setSubmit(false);
  }

  if (submit === false) {
    return (
      <div>
        <Header />
        <section className="marcas_header">
          {/*  <article className="marcas_banner">
            <div className="marcas_img_plataforma_box">
              <img
                className="marcas_img_plataforma"
                src={logoPlataformaCero}
                alt="Logo Plataforma Cero"
              />
            </div>
          </article> */}
          <div className="marcas_header_img"></div>
        </section>
        <section className="marcas" id="marcas">
          <h2 className="marcas_title">
            ¿Quieres que tus acciones de sampling lleguen más y mejor a tu
            público, esté donde esté?
          </h2>
          <h3 className="marcas_title_2">
            No lo dudes. Somos exactamente lo que necesitas. Contrata nuestros
            servicios y déjate sorprender por los resultados.
          </h3>
          <h3 className="marcas_title_3">
            Si nos dejas aquí tus datos, en un máximo de 7 días,
          </h3>
          <h3 className="marcas_title_4 final">
            nos pondremos en contacto contigo.
          </h3>
          <article className="marcas_fields">
            <form className="marcas_inputs" onSubmit={sendFormInfo}>
              <label className="marcas_label" htmlFor="nombre">
                Nombre
              </label>
              <input
                className="marcas_input"
                type="text"
                id="nombre"
                name="nombre"
                placeholder="Tu nombre"
                onChange={getFormInfo}
                value={formInfo.nombre}
              ></input>
              <label className="marcas_label" htmlFor="telefono">
                Teléfono
              </label>
              <input
                className="marcas_input"
                type="text"
                id="telefono"
                name="telefono"
                placeholder="Tu teléfono"
                onChange={getFormInfo}
                value={formInfo.telefono}
              ></input>
              <label className="marcas_label" htmlFor="email">
                Email
              </label>
              <input
                className="marcas_input"
                type="email"
                id="email"
                name="email"
                placeholder="Tu email"
                onChange={getFormInfo}
                value={formInfo.email}
              />
              <label className="marcas_label" htmlFor="nombre2">
                ¿Qué marca quieres promocionar?
              </label>
              <input
                className="marcas_input"
                type="text"
                id="marca"
                name="marca"
                onChange={getFormInfo}
                value={formInfo.marca}
              ></input>
              <label className="marcas_label" htmlFor="nombre2">
                ¿Cuál es el target de tu marca?
              </label>
              <input
                className="marcas_input"
                type="text"
                id="target"
                name="target"
                onChange={getFormInfo}
                value={formInfo.marca}
              ></input>
              <button className="marcas_submit" type="submit">
                Enviar
              </button>
              <p
                className={`error_message ${errorMessage ? "show_error" : ""}`}
              >
                Rellena todos los campos antes de enviar el formulario
              </p>
            </form>
          </article>
        </section>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Header />
        <section className="marcas_2" id="marcas">
          <h2 className="marcas_title">
            ¡Gracias por tu <span className="highlight_green">mensaje!</span>
          </h2>
          <h2 className="marcas_title_3">
            Nos pondremos en contacto contigo{" "}
            <span className="highlight_green">enseguida.</span>
          </h2>
          <div className="home_button">
            <Link to="/" className="home_link" onClick={resetSubmit}>
              Volver a la home
            </Link>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Marcas;
