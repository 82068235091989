import "../../styles/components/Areas.scss";

function Areas() {
  //Insertar condicion despues porque ahora me está dando problemas de renderizado
  if (window.innerWidth < 700) {
    return (
      <section className="areas" id="servicios">
        <div className="areas_img_box blank_space">
          <p className="hero_text_4">Nuestra estrategia,</p>
          <p className="hero_text_5"> estar donde</p>
          <p className="hero_text_6"> están</p>
        </div>
        <div className="areas_gaming_box">
          <p className="areas_gaming_text">Gaming</p>
        </div>
        <div className="areas_espacios_box">
          <p className="areas_espacios_text_1">Espacios</p>
          <p className="areas_espacios_text_2">Eventos urbanos</p>
        </div>
        <div className="areas_estudio_box">
          <p className="areas_estudio_text_1">Estudio</p>
          <p className="areas_estudio_text_2">Trabajo</p>
        </div>
        <div className="areas_musica_box">
          <p className="areas_musica_text">Música</p>
        </div>
        <div className="areas_entretenimiento_box">
          <p className="areas_entretenimiento_text">Entretenimiento</p>
        </div>
        <div className="areas_deporte_box">
          <p className="areas_deporte_text">Deporte</p>
        </div>
        <div className="areas_amigos_box">
          <p className="areas_amigos_text_1">Amigos</p>
          <p className="areas_amigos_text_2">Familia</p>
        </div>
      </section>
    );
  } else if (window.innerWidth >= 700) {
    return (
      <section className="areas" id="servicios">
        <div className="areas_gaming_box">
          <p className="areas_gaming_text">Gaming</p>
        </div>
        <div className="areas_img_box blank_space">
          <p className="hero_text_4">Nuestra estrategia,</p>
          <p className="hero_text_5"> estar donde</p>
          <p className="hero_text_6"> están</p>
        </div>
        <div className="areas_espacios_box">
          <p className="areas_espacios_text_1">Espacios</p>
          <p className="areas_espacios_text_2">Eventos urbanos</p>
        </div>
        <div className="areas_estudio_box">
          <p className="areas_estudio_text_1">Estudio</p>
          <p className="areas_estudio_text_2">Trabajo</p>
        </div>
        <div className="areas_musica_box">
          <p className="areas_musica_text">Música</p>
        </div>
        <div className="areas_entretenimiento_box">
          <p className="areas_entretenimiento_text">Entretenimiento</p>
        </div>
        <div className="areas_deporte_box">
          <p className="areas_deporte_text">Deporte</p>
        </div>
        <div className="areas_amigos_box">
          <p className="areas_amigos_text_1">Amigos</p>
          <p className="areas_amigos_text_2">Familia</p>
        </div>
      </section>
    );
  }
}

export default Areas;
