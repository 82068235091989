import Carousel from "react-bootstrap/Carousel";
import amstel from "../../images/logo_amstel.png";
import eag from "../../images/logo_eag.png";
import halls from "../../images/Logo halls.png";
import trident from "../../images/Logo trident.png";
import amazon from "../../images/Logo amazon.png";
import milka from "../../images/logo_milka.png";
import ultima from "../../images/logo_ultima.png";
import "../../styles/components/Amigos.scss";

function Amigos() {
  if (window.innerWidth < 700) {
    return (
      <section className="carousel">
        <p className="carousel_title">
          Nuestros
          <span className="highlight_green"> amigos</span>
        </p>
        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel__images__box">
              <article className="carousel__images__card">
                <img
                  className="carousel__images__image"
                  src={amstel}
                  alt="First slide"
                  id="1"
                />
              </article>
              <article className="carousel__images__card halls">
                <img
                  className="carousel__images__image"
                  src={halls}
                  alt="First slide"
                  id="2"
                />
              </article>
              <article className="carousel__images__card milka">
                <img
                  className="carousel__images__image"
                  src={milka}
                  alt="First slide"
                  id="3"
                />
              </article>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel__images__box">
              <article className="carousel__images__card eag">
                <img
                  className="carousel__images__image"
                  src={eag}
                  alt="First slide"
                  id="4"
                />
              </article>
              <article className="carousel__images__card ultima">
                <img
                  className="carousel__images__image"
                  src={ultima}
                  alt="First slide"
                  id="6"
                />
              </article>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel__images__box">
              <article className="carousel__images__card trident">
                <img
                  className="carousel__images__image"
                  src={trident}
                  alt="First slide"
                  id="5"
                />
              </article>
              <article className="carousel__images__card amazon">
                <img
                  className="carousel__images__image"
                  src={amazon}
                  alt="First slide"
                  id="7"
                />
              </article>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    );
  } else if (window.innerWidth >= 700 && window.innerWidth < 1190) {
    return (
      <section className="carousel">
        <p className="carousel_title">
          Nuestros
          <span className="highlight_green"> amigos</span>
        </p>
        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel__images__box">
              <article className="carousel__images__card">
                <img
                  className="carousel__images__image"
                  src={amstel}
                  alt="First slide"
                  id="1"
                />
              </article>
              <article className="carousel__images__card halls">
                <img
                  className="carousel__images__image"
                  src={halls}
                  alt="First slide"
                  id="2"
                />
              </article>
              <article className="carousel__images__card milka">
                <img
                  className="carousel__images__image"
                  src={milka}
                  alt="First slide"
                  id="3"
                />
              </article>
              <article className="carousel__images__card trident">
                <img
                  className="carousel__images__image"
                  src={trident}
                  alt="First slide"
                  id="6"
                />
              </article>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel__images__box">
              <article className="carousel__images__card ultima">
                <img
                  className="carousel__images__image"
                  src={ultima}
                  alt="First slide"
                  id="5"
                />
              </article>
              <article className="carousel__images__card eag">
                <img
                  className="carousel__images__image"
                  src={eag}
                  alt="First slide"
                  id="4"
                />
              </article>
              <article className="carousel__images__card amazon">
                <img
                  className="carousel__images__image"
                  src={amazon}
                  alt="First slide"
                  id="7"
                />
              </article>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    );
  } else if (window.innerWidth >= 1190) {
    return (
      <section className="carousel">
        <p className="carousel_title">
          Nuestros
          <span className="highlight_green"> amigos</span>
        </p>
        <Carousel interval={null}>
          <Carousel.Item>
            <div className="carousel__images__box">
              <article className="carousel__images__card amstel">
                <img
                  className="carousel__images__image"
                  src={amstel}
                  alt="First slide"
                  id="1"
                />
              </article>
              <article className="carousel__images__card halls">
                <img
                  className="carousel__images__image"
                  src={halls}
                  alt="First slide"
                  id="2"
                />
              </article>
              <article className="carousel__images__card milka">
                <img
                  className="carousel__images__image"
                  src={milka}
                  alt="First slide"
                  id="3"
                />
              </article>
              <article className="carousel__images__card eag">
                <img
                  className="carousel__images__image"
                  src={eag}
                  alt="First slide"
                  id="4"
                />
              </article>
              <article className="carousel__images__card ultima">
                <img
                  className="carousel__images__image"
                  src={ultima}
                  alt="First slide"
                  id="5"
                />
              </article>
              <article className="carousel__images__card trident">
                <img
                  className="carousel__images__image"
                  src={trident}
                  alt="First slide"
                  id="6"
                />
              </article>
              <article className="carousel__images__card amazon">
                <img
                  className="carousel__images__image"
                  src={amazon}
                  alt="First slide"
                  id="7"
                />
              </article>
            </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className="carousel__images__box">
              <article className="carousel__images__card amstel">
                <img
                  className="carousel__images__image"
                  src={amstel}
                  alt="First slide"
                  id="1"
                />
              </article>
              <article className="carousel__images__card halls">
                <img
                  className="carousel__images__image"
                  src={halls}
                  alt="First slide"
                  id="2"
                />
              </article>
              <article className="carousel__images__card milka">
                <img
                  className="carousel__images__image"
                  src={milka}
                  alt="First slide"
                  id="3"
                />
              </article>
              <article className="carousel__images__card eag">
                <img
                  className="carousel__images__image"
                  src={eag}
                  alt="First slide"
                  id="4"
                />
              </article>
              <article className="carousel__images__card ultima">
                <img
                  className="carousel__images__image"
                  src={ultima}
                  alt="First slide"
                  id="5"
                />
              </article>
              <article className="carousel__images__card trident">
                <img
                  className="carousel__images__image"
                  src={trident}
                  alt="First slide"
                  id="6"
                />
              </article>
              <article className="carousel__images__card amazon">
                <img
                  className="carousel__images__image"
                  src={amazon}
                  alt="First slide"
                  id="7"
                />
              </article>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>
    );
  }
}

export default Amigos;
