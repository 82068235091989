import "../../styles/components/Cifras.scss";
import flechaVerde from "../../images/flechas Verdes.png";
import { NavLink } from "react-router-dom";

function Cifras() {
  if (window.innerWidth < 993) {
    return (
      <section className="cifras">
        <article className="cifras_data">
          <div className="cifras_data_box" id="hover_box">
            <p className="cifras_data_text1">+2M</p>
            <p className="cifras_data_text2">Muestras entregadas</p>
          </div>
          <div className="cifras_data_box" id="hover_box">
            <p className="cifras_data_text1">+500</p>
            <p className="cifras_data_text2">
              Embajadores y puntos de distribución en toda España
            </p>
          </div>
          <div className="cifras_data_box" id="hover_box">
            <p className="cifras_data_text1">+5M</p>
            <p className="cifras_data_text2">Alcance digital orgánico</p>
          </div>
          <div className="cifras_data_box" id="hover_box">
            <p className="cifras_data_text3 big_title">Plataforma Online</p>
            <p className="cifras_data_text4">Seguimiento en tiempo real</p>
          </div>
        </article>
        <div className="cifras_text">
          <div className="cifras_text_box1">
            <span className="cifras_highlight_grey">Con nosotros </span>tus
            muestras de producto llegarán a todos los rincones.{" "}
          </div>
          <span className="cifras_highlight_grey centered">
            Y lo mejor de todo, verás resultados en tiempo real.
          </span>
        </div>
        <p className="cifras_text_2">
          Ya sabes: del cero... al infinito.{" "}
          <span className="cifras_highlight_green">¿Empezamos?</span>
        </p>
        <article className="cifras_link_wrapper">
          <section className="cifras_link_wrapper_1">
            <NavLink to={"/marcas"} className="cifras_link" target="_blank">
              <div className="cifras_box_1">
                <h2 className="cifras_box_1_title">
                  ¿Quieres que tu producto llegue a todas partes?
                </h2>
                <div className="cifras_box_1_img--wrapper">
                  <img
                    src={flechaVerde}
                    className="cifras_box_1_img--wrapper_img"
                    alt="Flecha verde"
                  ></img>
                </div>
              </div>
            </NavLink>
          </section>
          <section className="cifras_link_wrapper_2">
            <NavLink
              to={"/embajadores"}
              className="cifras_link"
              target="_blank"
            >
              <div className="cifras_box_2">
                <h2 className="cifras_box_2_title">
                  ¿Quieres recibir producto en tu negocio/evento para tus
                  clientes?
                </h2>
                <div className="cifras_box_2_img--wrapper">
                  <img
                    src={flechaVerde}
                    className="cifras_box_2_img--wrapper_img"
                    alt="Flecha verde"
                  ></img>
                </div>
              </div>
            </NavLink>
          </section>
        </article>
      </section>
    );
  } else if (window.innerWidth >= 993) {
    return (
      <section className="cifras">
        <article className="cifras_data">
          <div className="cifras_data_box" id="hover_box">
            <p className="cifras_data_text1">+2M</p>
            <p className="cifras_data_text2">Muestras entregadas</p>
          </div>
          <div className="cifras_data_box" id="hover_box">
            <p className="cifras_data_text1">+500</p>
            <p className="cifras_data_text2">
              Embajadores y puntos de distribución en toda España
            </p>
          </div>
          <div className="cifras_data_box" id="hover_box">
            <p className="cifras_data_text1">+5M</p>
            <p className="cifras_data_text2">Alcance digital orgánico</p>
          </div>
          <div className="cifras_data_box" id="hover_box">
            <p className="cifras_data_text3 big_title">Plataforma Online</p>
            <p className="cifras_data_text4">Seguimiento en tiempo real</p>
          </div>
        </article>
        <div className="cifras_text">
          <span className="cifras_highlight_grey">Con nosotros </span>tus
          muestras de producto llegarán a todos los rincones.{" "}
          <span className="cifras_highlight_grey centered">
            Y lo mejor de todo, verás resultados en tiempo real.
          </span>
        </div>
        <p className="cifras_text_2">
          Ya sabes: del cero... al infinito.{" "}
          <span className="cifras_highlight_green">¿Empezamos?</span>
        </p>
        <article className="cifras_link_wrapper">
          <section className="cifras_link_wrapper_1">
            <NavLink to={"/marcas"} className="cifras_link" target="_blank">
              <div className="cifras_box_1">
                <h2 className="cifras_box_1_title">
                  ¿Quieres que tu producto llegue a todas partes?
                </h2>
                <div className="cifras_box_1_img--wrapper">
                  <img
                    src={flechaVerde}
                    className="cifras_box_1_img--wrapper_img"
                    alt="Flecha verde"
                  ></img>
                </div>
              </div>
            </NavLink>
          </section>
          <section className="cifras_link_wrapper_2">
            <NavLink
              to={"/embajadores"}
              className="cifras_link"
              target="_blank"
            >
              <div className="cifras_box_2">
                <h2 className="cifras_box_2_title">
                  ¿Quieres recibir producto en tu negocio/evento para tus
                  clientes?
                </h2>
                <div className="cifras_box_2_img--wrapper">
                  <img
                    src={flechaVerde}
                    className="cifras_box_2_img--wrapper_img"
                    alt="Flecha verde"
                  ></img>
                </div>
              </div>
            </NavLink>
          </section>
        </article>
      </section>
    );
  }
}

export default Cifras;
