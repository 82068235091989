import Header from "../reusables/Header";
import Hero from "./Hero";
import Areas from "./Areas";
import Resultados from "./Cifras";
import Razones from "./Razones";
import Form from "./Form";
import Amigos from "./Amigos";
import Footer from "../reusables/Footer";

function Landing(props) {
  console.log("hola" + props);
  return (
    <>
      <Header />
      <Hero />
      <Resultados />
      <Areas />
      <Razones />
      <Amigos />
      <Form
        sendLandingFormInfo={props.sendLandingFormInfo}
        resetSubmit={props.resetSubmit}
        submit={props.submit}
        setSubmit={props.setSubmit}
      />
      {/*   <Banner resetSubmit={props.resetSubmit} /> */}
      <Footer
        setSubmit={props.setSubmit}
        resetSubmit={props.resetSubmit}
        submit={props.submit}
      />
    </>
  );
}
export default Landing;
