import "../../styles/components/Embajadores.scss";
import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import { useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
/* import logoPlataformaCero from "../../images/logo_plataforma.png";
 */

function Embajadores(props) {
  const [formInfo, setFormInfo] = useState({
    nombre: "",
    telefono: "",
    email: "",
    tipo: "",
    nombre_tipo: "",
    fecha: "",
    cerveza: "no",
    chuches: "no",
    snacks: "no",
    bebidas_espirituosas: "no",
  });
  const [errorMessage, setErrorMessage] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [eventoChecked, setEventoChecked] = useState(false);

  function getFormInfo(ev) {
    const input = ev.target.name;
    const value = ev.target.value;
    const checked = ev.target.checked;

    if (input === "nombre") {
      setFormInfo({ ...formInfo, nombre: value });
    } else if (input === "telefono") {
      setFormInfo({ ...formInfo, telefono: value });
    } else if (input === "email") {
      setFormInfo({ ...formInfo, email: value });
    } else if (input === "negocio" && checked === true) {
      setFormInfo({ ...formInfo, tipo: input });
      setEventoChecked(false);
    } else if (input === "evento" && checked === true) {
      setFormInfo({ ...formInfo, tipo: input });
      setEventoChecked(true);
    } else if (input === "nombre_tipo") {
      setFormInfo({ ...formInfo, nombre_tipo: value });
    } else if (input === "fecha") {
      setFormInfo({ ...formInfo, fecha: value });
    } else if (
      input === "cerveza" &&
      checked === true &&
      formInfo.cerveza === "no"
    ) {
      setFormInfo({ ...formInfo, cerveza: "si" });
    } else if (
      input === "cerveza" &&
      checked === false &&
      formInfo.cerveza === "si"
    ) {
      setFormInfo({ ...formInfo, cerveza: "no" });
    } else if (
      input === "chuches" &&
      checked === true &&
      formInfo.chuches === "no"
    ) {
      setFormInfo({ ...formInfo, chuches: "si" });
    } else if (
      input === "chuches" &&
      checked === false &&
      formInfo.chuches === "si"
    ) {
      setFormInfo({ ...formInfo, chuches: "no" });
    } else if (
      input === "snacks" &&
      checked === true &&
      formInfo.snacks === "no"
    ) {
      setFormInfo({ ...formInfo, snacks: "si" });
    } else if (
      input === "snacks" &&
      checked === false &&
      formInfo.snacks === "si"
    ) {
      setFormInfo({ ...formInfo, snacks: "no" });
    } else if (
      input === "bebidas_espirituosas" &&
      checked === true &&
      formInfo.bebidas_espirituosas === "no"
    ) {
      setFormInfo({ ...formInfo, bebidas_espirituosas: "si" });
    } else if (
      input === "bebidas_espirituosas" &&
      checked === false &&
      formInfo.bebidas_espirituosas === "si"
    ) {
      setFormInfo({ ...formInfo, bebidas_espirituosas: "no" });
    }
  }

  function sendFormInfo(ev) {
    ev.preventDefault();

    if (
      formInfo.nombre === "" ||
      formInfo.email === "" ||
      formInfo.telefono === "" ||
      formInfo.tipo === "" ||
      formInfo.nombre_tipo === "" ||
      (formInfo.nombre === "" &&
        formInfo.email === "" &&
        formInfo.telefono === "" &&
        formInfo.tipo === "" &&
        formInfo.nombre_tipo === "")
    ) {
      setErrorMessage(true);
    } else {
      props.sendembajadoresFormInfo(formInfo);
      setSubmit(true);
      resetForm();
      setErrorMessage(false);
    }
  }

  function resetForm() {
    setFormInfo({
      nombre: "",
      telefono: "",
      email: "",
      tipo: "",
      nombre_tipo: "",
      fecha: "",
      cerveza: "no",
      chuches: "no",
      snacks: "no",
      bebidas_espirituosas: "no",
    });
  }

  function resetSubmit() {
    setSubmit(false);
  }

  if (submit === false) {
    return (
      <div>
        <Header />
        <section className="embajadores_header">
          {/* <article className="embajadores_banner">
            <div className="embajadores_img_plataforma_box">
              <img
                className="embajadores_img_plataforma"
                src={logoPlataformaCero}
                alt="Logo Plataforma Cero"
              />
            </div>
          </article> */}
          <div className="embajadores_header_img"></div>
        </section>
        <section className="embajadores" id="embajadores">
          <h2 className="embajadores_title">
            ¿Quieres que te enviemos muestras de productos para que las puedas
            repartir entre los clientes de tu negocio?
          </h2>
          <h3 className="embajadores_title_2">
            Ahora puedes tener un detallazo con todos ellos. O sorprenderles aún
            más en tu próximo evento.
          </h3>
          <h3 className="embajadores_title_3">
            Déjanos tus datos y te contamos cómo.
          </h3>
          <h3 className="embajadores_title_4 final">
            En un máximo de 7 días, nos pondremos en contacto contigo.
          </h3>
          <article className="embajadores_fields">
            <form className="embajadores_inputs" onSubmit={sendFormInfo}>
              <label className="embajadores_label" htmlFor="nombre">
                Nombre
              </label>
              <input
                className="embajadores_input"
                type="text"
                id="nombre"
                name="nombre"
                placeholder="Tu nombre"
                onChange={getFormInfo}
                value={formInfo.nombre}
              ></input>
              <label className="embajadores_label" htmlFor="telefono">
                Teléfono
              </label>
              <input
                className="embajadores_input"
                type="text"
                id="telefono"
                name="telefono"
                placeholder="Tu teléfono"
                onChange={getFormInfo}
                value={formInfo.telefono}
              ></input>
              <label className="embajadores_label" htmlFor="email">
                Email
              </label>
              <input
                className="embajadores_input"
                type="email"
                id="email"
                name="email"
                placeholder="Tu email"
                onChange={getFormInfo}
                value={formInfo.email}
              />
              <label className="embajadores_label" htmlFor="que_eres">
                ¿Qué eres?
              </label>
              <div className="embajadores_input_radio--box">
                <input
                  className="embajadores_input_radio"
                  type="checkbox"
                  id="negocio"
                  name="negocio"
                  onChange={getFormInfo}
                  checked={formInfo.tipo === "negocio" ? true : false}
                />
                <label className="embajadores_label_2" htmlFor="negocio">
                  Negocio
                </label>
              </div>
              <div className="embajadores_input_radio--box">
                <input
                  className="embajadores_input_radio"
                  type="checkbox"
                  id="evento"
                  name="evento"
                  onChange={getFormInfo}
                  checked={formInfo.tipo === "evento" ? true : false}
                ></input>
                <label className="embajadores_label_2" htmlFor="evento">
                  Evento
                </label>
              </div>
              <label className="embajadores_label" htmlFor="nombre2">
                Nombre del negocio / evento
              </label>
              <input
                className="embajadores_input"
                type="text"
                id="nombre_tipo"
                name="nombre_tipo"
                onChange={getFormInfo}
                value={formInfo.nombre_tipo}
              ></input>
              <label
                className={
                  "embajadores_label fecha " +
                  (eventoChecked === true ? "show" : "collapsed")
                }
                htmlFor="fecha"
              >
                Fecha del evento
              </label>
              <input
                className={
                  "embajadores_input fecha " +
                  (eventoChecked === true ? "show" : "collapsed")
                }
                type="date"
                id="fecha"
                name="fecha"
                onChange={getFormInfo}
                value={formInfo.fecha}
              ></input>
              <label className="embajadores_label" htmlFor="producto">
                ¿En qué tipo de producto estás interesado?
              </label>
              <div className="embajadores_input_radio--box">
                <input
                  className="embajadores_input_radio"
                  type="checkbox"
                  id="cerveza"
                  name="cerveza"
                  onChange={getFormInfo}
                  checked={formInfo.cerveza === "si" ? true : false}
                ></input>
                <label className="embajadores_label_2" htmlFor="cerveza">
                  Cerveza
                </label>
              </div>
              <div className="embajadores_input_radio--box">
                <input
                  className="embajadores_input_radio"
                  type="checkbox"
                  id="chuches"
                  name="chuches"
                  onChange={getFormInfo}
                  checked={formInfo.chuches === "si" ? true : false}
                ></input>
                <label className="embajadores_label_2" htmlFor="chuches">
                  Chuches
                </label>
              </div>
              <div className="embajadores_input_radio--box">
                <input
                  className="embajadores_input_radio"
                  type="checkbox"
                  id="snacks"
                  name="snacks"
                  onChange={getFormInfo}
                  checked={formInfo.snacks === "si" ? true : false}
                ></input>
                <label className="embajadores_label_2" htmlFor="snacks">
                  Snacks
                </label>
              </div>
              <div className="embajadores_input_radio--box">
                <input
                  className="embajadores_input_radio"
                  type="checkbox"
                  id="bebidas_espirituosas"
                  name="bebidas_espirituosas"
                  onChange={getFormInfo}
                  checked={
                    formInfo.bebidas_espirituosas === "si" ? true : false
                  }
                ></input>
                <label
                  className="embajadores_label_2"
                  htmlFor="bebidas_espirituosas"
                >
                  Bebidas espirituosas
                </label>
              </div>
              <button className="embajadores_submit" type="submit">
                Enviar
              </button>
              <p
                className={`error_message ${errorMessage ? "show_error" : ""}`}
              >
                Rellena todos los campos antes de enviar el formulario
              </p>
            </form>
          </article>
        </section>
        <Footer />
      </div>
    );
  } else {
    return (
      <div>
        <Header />
        <section className="embajadores_2" id="embajadores">
          <h2 className="embajadores_title">
            ¡Gracias por tu <span className="highlight_green">mensaje!</span>
          </h2>
          <h2 className="embajadores_title_3">
            Nos pondremos en contacto contigo{" "}
            <span className="highlight_green">enseguida.</span>
          </h2>
          <div className="home_button">
            <Link to="/" className="home_link" onClick={resetSubmit}>
              Volver a la home
            </Link>
          </div>
        </section>
        <Footer />
      </div>
    );
  }
}

export default Embajadores;
