import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import Form from "../landing/Form";
import "../../styles/components/Privacidad.scss";

function Privacidad() {
  return (
    <div>
      <Header />
      <h1 className="privacidad_main_title">
        POLÍTICA DE PRIVACIDAD Y PROTECCIÓN DE DATOS
      </h1>
      <main className="privacidad_main">
        <section>
          <h2 className="privacidad_secondary_title">
            1.- ¿Quién es el Responsable de tratamiento de sus datos?
          </h2>
          <p className="privacidad_text">
            El responsable de los tratamientos de datos referentes a los
            distintos procesos con respecto la gestión de usuarios, clientes,
            clientes potenciales, colaboradores y proveedores es IDEAS DESDE
            CERO S.L. , con domicilio social en C/MANUEL URIBE, 13-15 LOCAL
            28033 (MADRID) y CIF B86272564.
          </p>
          <p className="privacidad_text">
            A efectos de nuestra política de protección de datos el teléfono de
            contacto es 917 218 786 y el correo electrónico de contacto es{" "}
            <a
              target="_blank"
              className="privacidad_link"
              href="mailto:sampling@ideasdesdecero.es"
            >
              administracion@ideasdesdecero.es
            </a>
          </p>
          <h2 className="privacidad_secondary_title">
            2.- ¿Qué tipo de datos tenemos sobre su persona y cómo los hemos
            obtenido?
          </h2>
          <p className="privacidad_text">
            Las categorías de datos personales que IDEAS DESDE CERO S.L. trata
            sobre sus clientes y proveedores son:
          </p>
          <ul className="privacidad_list">
            <li>Datos de identificación</li>
            <li>Datos de contacto</li>
            <li>Direcciones postales o electrónicas</li>
            <li>Información comercial</li>
            <li>Datos económicos y de transacciones</li>
            <li>Datos profesionales</li>
          </ul>
          <p className="privacidad_text">
            Todos los datos arriba mencionados los hemos obtenido o directamente
            de Usted mediante la presentación de una oferta comercial, propuesta
            contractual, etc. o mediante su empresa al facilitarnos los datos de
            identificación y demás información necesaria para llevar al cabo el
            objeto de la relación contractual entre las partes. Será una
            obligación suya o de su empresa facilitarnos los datos actualizados
            en caso de modificación. Con respecto a los datos de candidatos a
            empleo hemos obtenido sus datos mediante la presentación de un CV
            por su parte o la obtención de su candidatura mediante los servicios
            de un portal de empleo. En este último caso Usted ha autorizado
            dicha cesión de sus datos a nuestra empresa al insertar sus datos
            curriculares en el portal de empleo.
          </p>
          <h2 className="privacidad_secondary_title">
            3.- ¿Por cuánto tiempo conservaremos sus datos?
          </h2>
          <p className="privacidad_text">
            Los datos personales relativos a personas físicas vinculadas a
            clientes potenciales, clientes y proveedores que IDEAS DESDE CERO
            S.L. recopile mediante los distintos formularios de contacto y/o
            recogida de información se conservarán mientras no se solicite su
            supresión por el interesado. Los datos proporcionados por nuestros
            clientes y proveedores se conservarán mientras se mantenga la
            relación mercantil entre las partes respetando en cualquier caso los
            plazos mínimos legales de conservación según la materia.
          </p>
          <p className="privacidad_text">
            En cualquier caso IDEAS DESDE CERO S.L. guardará sus datos
            personales durante el período de tiempo que sea razonablemente
            necesario teniendo en cuenta nuestras necesidades de dar respuesta a
            cuestiones que se planteen o resolver problemas, realizar mejoras,
            activar nuevos servicios y cumplir los requisitos que exija la
            legislación aplicable. Esto significa que podemos conservar tus
            datos personales durante un período de tiempo razonable incluso
            después de que hayas dejado de usar nuestros productos o de que
            hayas dejado de usar esta página web. Después de este período, tus
            datos personales serán eliminados de todos los sistemas de IDEAS
            DESDE CERO S.L.
          </p>
          <h2 className="privacidad_secondary_title">
            4.- ¿Con qué finalidad y sobre qué base de legitimación tratamos sus
            datos?
          </h2>
          <p className="privacidad_text">
            En IDEAS DESDE CERO S.L. tratamos los datos que nos facilitan las
            personas interesadas con el fin de gestionar distintas actividades
            derivadas de procedimientos específicos realizados en materia de
            ventas, servicio post venta, gestión de proveedores, gestión de
            candidatos, calidad de servicios, etc. De esta manera, utilizaremos
            sus datos para llevar al cabo algunas de las siguientes acciones
            basándonos en la respectiva base de legitimación:
          </p>
          <table className="privacidad_table">
            <thead>
              <tr className="privacidad_table_main--row">
                <th className="privacidad_table_cell">
                  FINALIDAD DE TRATAMIENTO
                </th>
                <th className="privacidad_table_cell">BASE DE LEGITIMACIÓN</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="privacidad_table_cell">
                  Gestión de los derechos de protección de datos de los
                  interesados
                </td>
                <td className="privacidad_table_cell">
                  Obligación regulatoria
                </td>
              </tr>
              <tr>
                <td className="privacidad_table_cell">
                  Procesos de búsqueda, evaluación y selección de candidatos
                </td>
                <td className="privacidad_table_cell">
                  Consentimiento del afectado
                </td>
              </tr>
              <tr>
                <td className="privacidad_table_cell">
                  Gestión fiscal, contable y administrativa con colaboradores
                </td>
                <td className="privacidad_table_cell">
                  Ejecución (pre)contrato Obligación regulatoria
                </td>
              </tr>
              <tr>
                <td className="privacidad_table_cell">
                  Gestión fiscal, contable y administrativa con proveedores
                </td>
                <td className="privacidad_table_cell">
                  Ejecución (pre)contrato Obligación regulatoria
                </td>
              </tr>
              <tr>
                <td className="privacidad_table_cell">
                  Segmentación de potenciales clientes, realización de campañas
                  de captación, exclusión publicitaria
                </td>
                <td className="privacidad_table_cell">
                  Consentimiento del afectado
                </td>
              </tr>
              <tr>
                <td className="privacidad_table_cell">
                  Operaciones de publicidad y marketing para la fidelización de
                  clientes
                </td>
                <td className="privacidad_table_cell">
                  Interés legítimo del interesado
                </td>
              </tr>
              <tr>
                <td className="privacidad_table_cell">
                  Gestión de las solicitudes de información que llegan a través
                  del formulario web o RRSS
                </td>
                <td className="privacidad_table_cell">
                  Consentimiento del afectado
                </td>
              </tr>
              <tr>
                <td className="privacidad_table_cell">
                  Gestión contable, fiscal, administrativa y facturación de
                  clientes
                </td>
                <td className="privacidad_table_cell">
                  Ejecución (pre)contrato Obligación regulatoria
                </td>
              </tr>
              <tr>
                <td className="privacidad_table_cell no_border">
                  Atender a requerimientos recibidos por parte de la
                  Administración
                </td>
                <td className="privacidad_table_cell no_border">
                  Obligación regulatoria
                </td>
              </tr>
            </tbody>
          </table>
          <p className="privacidad_text">
            En cuanto, a la base de legitimación referenciada Usted se encuentra
            obligado a facilitar los datos personales, en el supuesto de que no
            facilite sus datos de carácter personal no se podrá ejecutar su
            contrato, cumplir las obligaciones legales o derivadas de los
            poderes públicos.
          </p>
          <p className="privacidad_text">
            No vamos a elaborar perfiles comerciales en base de la información
            facilitada y en consecuencia tampoco tomaremos decisiones
            automatizadas sobre Ud en base de un perfil comercial.
          </p>
          <h2 className="privacidad_secondary_title">
            5.- ¿A qué destinatarios se comunicarán sus datos?
          </h2>
          <p className="privacidad_text">
            IDEAS DESDE CERO S.L. nunca compartirá tus datos personales con
            ninguna empresa tercera que pretenda utilizarlos en sus acciones de
            marketing directo, excepto en el caso de que nos hayas autorizado
            expresamente a ello.
          </p>
          <p className="privacidad_text">
            Le informamos que podemos facilitar tus datos personales a
            organismos de la Administración Pública y Autoridades competentes en
            aquellos casos que IDEAS DESDE CERO S.L. , reciba un requerimiento
            legal por parte de dichas Autoridades o en los casos que, actuando
            de buena fe, consideramos que tal acción es razonablemente necesaria
            para cumplir con un proceso judicial; para contestar cualquier
            reclamación o demanda jurídica; o para proteger los derechos de
            IDEAS DESDE CERO S.L. , o sus clientes y el público en general.
          </p>
          <p className="privacidad_text">
            IDEAS DESDE CERO S.L. puede proporcionar tus datos personales a
            terceras personas (Ej. proveedores de servicios de Internet que nos
            ayudan a administrar nuestra página web o llevar a cabo los
            servicios contratados, empresas de soporte y mantenimiento
            informático, gestorías y asesoramiento fiscal y contable, etc.). En
            cualquier caso, estas terceras personas deben mantener, en todo
            momento, los mismos niveles de seguridad que IDEAS DESDE CERO S.L.
            en relación con tus datos personales y, cuando sea necesario,
            estarán vinculadas por compromisos legales a fin de guardar tus
            datos personales de forma privada y segura, y a fin de utilizar
            únicamente la información siguiendo instrucciones específicas de
            IDEAS DESDE CERO S.L.
          </p>
          <p className="privacidad_text">
            Le informamos que IDEAS DESDE CERO S.L. , puede comunicar sus datos,
            según la finalidad de servicio prestado, a los siguientes
            destinatarios ubicados fuera de la UE, teniendo dicho flujo de datos
            la consideración de transferencia internacional de datos conforme a
            la normativa vigente:
          </p>
          <h2 className="privacidad_secondary_title">
            6.- ¿Cuáles son sus derechos como afectados o interesado?
          </h2>
          <p className="privacidad_text">
            Cualquier persona tiene derecho a obtener confirmación sobre si
            IDEAS DESDE CERO S.L. está tratando datos personales que le
            conciernan, o no.
          </p>
          <p className="privacidad_text">
            En concreto, las personas interesadas pueden solicitar el
            <span className="highlight"> derecho de acceso</span> a sus datos
            personales, así como recibirlos en un formato común y lectura
            mecanizada si el tratamiento se efectúa por medios electrónicos
            <span className="highlight"> (derecho de portabilidad).</span>
          </p>
          <p className="privacidad_text">
            Asimismo, las personas interesadas pueden solicitar el
            <span className="highlight"> derecho de rectificación </span>
            de los datos inexactos o, en su caso, solicitar su supresión cuando,
            entre otros motivos, los datos ya no sea necesarios para los fines
            que fueron recogidos.
          </p>
          <p className="privacidad_text">
            Complementariamente, en determinadas circunstancias, los interesados
            podrán solicitar la{" "}
            <span className="highlight">
              limitación del tratamiento de sus datos
            </span>
            , o en determinadas circunstancias y por motivos relacionados con su
            situación particular, los interesados podrán ejercitar su
            <span className="highlight"> derecho a oponerse </span>
            al tratamiento de sus datos. IDEAS DESDE CERO S.L. dejará de tratar
            los datos, salvo por motivos legítimos imperiosos, o el ejercicio o
            la defensa de posibles reclamaciones o en aquellas excepciones
            establecidas en la normativa aplicable.
          </p>
          <p className="privacidad_text">
            Asimismo, le informamos que tiene derecho a retirar sus
            consentimientos otorgados en cualquier momento, sin que ello afecte
            a la licitud del tratamiento basado en el consentimiento previo a su
            retirada.
          </p>
          <p className="privacidad_text">
            Así mismo se informa al Usuario que en cualquier momento puede
            ejercitar los mencionados derechos dirigiéndose por escrito a
            nosotros utilizando los datos de contacto que aparecen en el
            Apartado 1, ‘Responsable de tratamiento’ de la presente política de
            Protección de Datos y Privacidad de IDEAS DESDE CERO S.L. ,
            adjuntando copia de su DNI.
          </p>
          <p className="privacidad_text">
            También tendrá el derecho a presentar una reclamación ante la
            Agencia Española de Protección de Datos, especialmente cuando no
            haya obtenido satisfacción en el ejercicio de sus derechos.
          </p>
          <p className="privacidad_text highlight no_margin">
            Agencia Española de Protección de Datos.
          </p>
          <p className="privacidad_text no_margin">C/ Jorge Juan, 6</p>
          <p className="privacidad_text no_margin">28001 – Madrid</p>
          <p className="privacidad_text no_margin number">
            Telf. 901100099 / 912663517
          </p>
          <h2 className="privacidad_secondary_title">
            7.- Protección de datos de los usuarios de la página web.
          </h2>
          <p className="privacidad_text">
            De conformidad con el vigente Reglamento (UE) 2016/679, IDEAS DESDE
            CERO S.L. informa que los datos de carácter personal de los Usuarios
            del sitio web se tratarán para la actividad del tratamiento indicado
            en cada formulario de recogida de datos de nuestra página web por
            parte de IDEAS DESDE CERO S.L. Dicho tratamiento de sus datos estará
            amparado en su propio consentimiento. Al pulsar el botón “ENVIAR”,
            el Usuario consiente al tratamiento de sus datos por parte de IDEAS
            DESDE CERO S.L.
          </p>
          <p className="privacidad_text">
            Asimismo, le informamos que salvo obligación legal o consentimiento
            expreso por su parte IDEAS DESDE CERO S.L. no va a ceder sus datos a
            terceras personas.
          </p>
          <p className="privacidad_text">
            Igualmente, se informa al Usuario que en cualquier momento puede
            ejercitar los derechos de acceso, rectificación o supresión de
            datos, así como disponer de otros derechos reconocidos en el
            presente documento y regulados en el Reglamento (UE) 2016/679,
            dirigiéndose por escrito a nosotros utilizando los datos de contacto
            que aparecen en el Apartado 1, ‘Responsable de Tratamiento’.
          </p>
          <p className="privacidad_text">
            Por otro lado, de acuerdo con lo dispuesto en la Ley 34/2002, de 11
            de Julio, de Servicios de la Sociedad de Información y de Comercio
            Electrónico, IDEAS DESDE CERO S.L. se compromete a no enviar
            publicidad a través del correo electrónico sin haber recabado antes
            la expresa autorización del destinatario. El Usuario podrá oponerse
            al envío de publicidad marcando la casilla correspondiente.
          </p>
          <h2 className="privacidad_secondary_title">
            8.- Otra información de interés sobre nuestra política de privacidad
          </h2>
          <h3 className="privacidad_tertiary_title">
            8.1.- Medidas de Seguridad
          </h3>
          <p className="privacidad_text">
            IDEAS DESDE CERO S.L. adopta los niveles de seguridad requeridos por
            la normativa Europea y Española vigente en protección de datos
            teniendo en cuenta el estado de la técnica, los costes de aplicación
            y la naturaleza, el alcance, el contexto y los fines del tratamiento
            descritos, así como los riesgos de probabilidad y gravedad variables
            para sus derechos y libertades como persona.
          </p>
          <h3 className="privacidad_tertiary_title">
            8.2.- Modificaciones de nuestra Política de Protección de Datos y de
            Privacidad
          </h3>
          <p className="privacidad_text">
            Ocasionalmente, IDEAS DESDE CERO S.L. podrá realizar modificaciones
            y correcciones en este apartado de Política de Protección de Datos
            para Clientes, Proveedores y Usuarios. Por favor, verifique este
            apartado regularmente para consultar los cambios que puedan haber
            existido y de qué manera le pueden afectar.
          </p>
          <h3 className="privacidad_tertiary_title">
            8.3.- ¿Por qué es necesario aceptar está Política de Protección de
            Datos y de Privacidad?
          </h3>
          <p className="privacidad_text">
            Este apartado le proporciona de un modo fácilmente accesible toda la
            información necesaria para que puedas conocer la tipología de datos
            que IDEAS DESDE CERO S.L. mantiene sobre sus usuarios, clientes
            potenciales, clientes y/o proveedores, las finalidades perseguidas,
            los derechos que la normativa de protección de datos reconoce a Ud.
            como persona afectada y el modo de cómo ejercer dichos derechos. Por
            lo tanto, con el envío deliberado de sus datos personales mediante
            nuestros medios de contacto y/o con el inicio de la relación
            mercantil con nuestra empresa consideramos que reconoce y acepta el
            tratamiento de tus datos personales tal y como se describe en la
            presente política. Esta información personal solamente se usará para
            los fines para los cuales nos la has facilitado o determinadas
            normativas nacionales o regionales nos habilitan hacerlo.
          </p>
          <p className="privacidad_text">
            En cualquier caso, debemos advertirle que una negativa por su parte
            para facilitarnos determinados datos solicitados podría obstaculizar
            el desarrollo de la relación contractual entre las partes con
            posibles consecuencias serias al momento de prestar las diversas
            prestaciones contempladas dentro del contrato mercantil celebrado
            con la parte contratante.
          </p>
          <p className="privacidad_text">
            Si tienes cualquier pregunta en relación con este apartado de
            Política de Protección de Datos para los Usuarios, Clientes
            Potenciales, Clientes y Proveedores de IDEAS DESDE CERO S.L. , por
            favor, póngase en contacto con la empresa usando la dirección
            facilitada en el Apartado 1, ‘Responsable de Tratamiento’ y
            estaremos encantados de atenderte y dar respuesta a las cuestiones
            adicionales que nos quieras plantear.
          </p>
          <h2 className="privacidad_secondary_title">
            9.- Legislación aplicable
          </h2>
          <p className="privacidad_text">
            Las presente Política de Protección de Datos se regirá en todo
            momento por lo dispuesto en la legislación española y europea en
            materia de protección de datos de carácter personal y de privacidad.
          </p>
        </section>
      </main>
      <Form />
      <Footer />
    </div>
  );
}

export default Privacidad;
