import "../../styles/components/Razones.scss";
import icono1 from "../../images/Icono 1.png";
import icono2 from "../../images/Icono 2.png";
import icono3 from "../../images/Icono 3.png";
import icono4 from "../../images/Icono 4.png";
import icono5 from "../../images/Icono 5.png";
import icono6 from "../../images/Icono 6.png";

function Razones() {
  return (
    <main>
      <section className="razones" id="nosotros">
        <h1 className="razones_title">
          ¿Por qué elegir
          <span className="highlight_green"> Plataforma Cero?</span>
        </h1>
        <article className="razones_box">
          <div className="razones_section_box">
            <div className="title_box">
              <div className="razones_image_box_nodos">
                <img className="razones_image" src={icono1} alt="Icono nodos" />
              </div>
              <h2 className="uppercase razones_title_2">
                Una exclusiva red de embajadores
              </h2>
            </div>
            <p className="razones_text_1">
              Para llegar con tus muestras de producto a donde nadie más puede
              llegar, hace falta contar con los mejores influencers y
              embajadores de marca.
            </p>
            <p className="razones_text_2">Y nosotros los tenemos.</p>
          </div>
          <div className="razones_section_box">
            <div className="title_box">
              <div className="razones_image_box_reloj">
                <img className="razones_image" src={icono2} alt="Icono reloj" />
              </div>
              <h2 className="uppercase razones_title_2">
                Seguimiento en real time
              </h2>
            </div>
            <p className="razones_text">
              Solo así podemos garantizar un perfecto control de la acción de
              principio a fin, y realizar ajustes just in time, si fuese
              necesario.
            </p>
          </div>
          <div className="razones_section_box">
            <div className="title_box">
              <div className="razones_image_box_map">
                <img className="razones_image" src={icono3} alt="Icono reloj" />
              </div>
              <h2 className="uppercase razones_title_2">Alcance nacional</h2>
            </div>
            <p className="razones_text">
              Con nuestra gran red de embajadores de marca, podemos abarcar todo
              el territorio nacional.
            </p>
          </div>
          <div className="razones_section_box">
            <div className="title_box">
              <div className="razones_image_box_24">
                <img className="razones_image" src={icono4} alt="Icono reloj" />
              </div>

              <h2 className="uppercase razones_title_2">Report 24/7 </h2>
            </div>
            <p className="razones_text">
              Recibirás un reporte al día de los logros de tus campañas, para
              que tengas un mayor control de los resultados que se vayan
              obteniendo.
            </p>
          </div>
          <div className="razones_section_box">
            <div className="title_box">
              <div className="razones_image_box_360">
                <img className="razones_image" src={icono5} alt="Icono reloj" />
              </div>

              <h2 className="uppercase razones_title_2">
                Servicio integral // Turnkey project
              </h2>
            </div>
            <p className="razones_text">
              Estás en las mejores manos. Tanto la estrategia de la acción como
              su ejecución, serán llevadas a cabo en su totalidad por nuestro
              propio equipo.
            </p>
          </div>
          <div className="razones_section_box">
            <div className="title_box">
              <div className="razones_image_box_dart">
                <img className="razones_image" src={icono6} alt="Icono reloj" />
              </div>
              <h2 className="uppercase razones_title_2">
                Target cualitativo y cuantitativo
              </h2>
            </div>
            <p className="razones_text">
              Para optimizar el impacto en el público deseado seguimos un
              procedimiento de segmentación que tiene en cuenta todas las
              posibles variantes.
            </p>
          </div>
        </article>
      </section>
    </main>
  );
}

export default Razones;
